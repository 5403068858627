<template>
  <div>
    <Create
      :tiposColaborador="tiposColaborador"
      :create="createItem"
      :close="closeCreate"
      :descSingular="descSingular"
    ></Create>
    <Edit
      :item="currentItem"
      :access="currentAccess"
      :sistemas="sistemas"
      :tiposColaborador="tiposColaborador"
      :modulos="modulos"
      :update="updateItem"
      :addAcesso="addAcesso"
      :remAcesso="remAcesso"
      :close="closeEdit"
      :descSingular="descSingular"
      :addAcessoModuloAcoes="addAcessoModuloAcoes"
      :remAcessoModuloAcoes="remAcessoModuloAcoes"
    ></Edit>
    <Loading :isActive="loading"></Loading>
    <CTabs variant="tabs" class="nav-tabs-boxed">
      <CTab :title="descPlural">
        <div class="filters row" style="margin-top:0">
          <div class="col-1-3">
              <span class="filters__search">
                <input type="text" name="" value="" placeholder="Buscar por...">
                <img style="position: relative; right: -10px;"src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPHBhdGggZD0iTTU1LjE0Niw1MS44ODdMNDEuNTg4LDM3Ljc4NmMzLjQ4Ni00LjE0NCw1LjM5Ni05LjM1OCw1LjM5Ni0xNC43ODZjMC0xMi42ODItMTAuMzE4LTIzLTIzLTIzcy0yMywxMC4zMTgtMjMsMjMgIHMxMC4zMTgsMjMsMjMsMjNjNC43NjEsMCw5LjI5OC0xLjQzNiwxMy4xNzctNC4xNjJsMTMuNjYxLDE0LjIwOGMwLjU3MSwwLjU5MywxLjMzOSwwLjkyLDIuMTYyLDAuOTIgIGMwLjc3OSwwLDEuNTE4LTAuMjk3LDIuMDc5LTAuODM3QzU2LjI1NSw1NC45ODIsNTYuMjkzLDUzLjA4LDU1LjE0Niw1MS44ODd6IE0yMy45ODQsNmM5LjM3NCwwLDE3LDcuNjI2LDE3LDE3cy03LjYyNiwxNy0xNywxNyAgcy0xNy03LjYyNi0xNy0xN1MxNC42MSw2LDIzLjk4NCw2eiIgZmlsbD0iIzAwNDE2ZiIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K" />
              </span>
          </div>
          <div class="col-2-3">
            <div class="pull-right">
              <button v-on:click="openCreate" class="button button-success button-block">
                <fa-icon icon="plus" />
                Criar
              </button>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th>Id</th>
              <th>Descrição</th>
              <th>Ativo</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in items">
              <td class="highlight">{{ item.id }}</td>
              <td>{{ item.descricao }}</td>
              <td>
                <span class="label success" v-if="item.ativo">Ativo</span>
                <span class="label error" v-else>Inativo</span>
              </td>
              <td class="actions">
                <button type="button" class="button button-primary" v-on:click="openEdit(item)"><fa-icon icon="pencil-alt" /></button>
                <button type="button" name="button" v-on:click="deleteConfirm(item.id)" class="button button-error"><fa-icon icon="trash-alt" /></button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </CTab>
    </CTabs>
  </div>
</template>
<script>
import clone from 'just-clone';
import { get, post, put, del } from '@/helpers/apiRequest';
import Create from './Create';
import Edit from './Edit';

export default {
  name: 'perfis',
  components: {
    Create,
    Edit,
  },
  props: [
    'sistemas'
  ],
  data() {
    return {
      descPlural: '',
      descSingular: '',
      loading: false,
      items: [],
      tiposColaborador: [],
      modulos: [],
      currentItem: {},
      currentAccess: false,
    };
  },
  methods: {
    loadDescricaoModulo() {
      const { modulos } = JSON.parse(localStorage.getItem('app'));
      const { descPlural, descSingular } = modulos.find(
        (m) => m.nome === 'ADMIN_PERFIL'
      );
      this.descPlural = descPlural;
      this.descSingular = descSingular;
    },
    loadPerfis() {
      this.loading = true;
      get('/admin/perfis')
        .then((json) => {
          this.items = json;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    loadTiposColaborador() {
      this.loading = true;
      get('/admin/perfis/tipos-colaborador')
        .then((json) => {
          this.tiposColaborador = json;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    openCreate() {
      this.$modal.show('perfilCreate');
    },
    closeCreate() {
      this.$modal.hide('perfilCreate');
    },
    createItem(item) {
      this.loading = true;
      post('/admin/perfis', item).then((json) => {
        this.items.push(json);
        this.loading = false;
        this.closeCreate();
        this.openEdit(json);
      });
    },
    openEdit(item, showLoading = true) {
      this.currentItem = clone(item);
      if (showLoading) {
        this.loading = true;
      }
      get(`/admin/perfis/${this.currentItem.id}`).then((json) => {
        this.currentAccess = json;
        if (showLoading) {
          this.loading = false;
          this.$modal.show('perfilEdit');
        }
      });
    },
    closeEdit() {
      this.$modal.hide('perfilEdit');
    },
    updateItem() {
      this.loading = true;
      const { id } = this.currentItem;

      put(`/admin/perfis/${id}`, this.currentItem)
        .then((json) => {
          this.loading = false;
          this.loadPerfis();
          this.closeEdit();
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    deleteConfirm(id) {
      this.$swal({
        title: 'Confirma Remover ?',
        text: 'Essa ação não poderá ser desfeita',
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
      })
        .then((res) => {
          if (res.value) {
            this.loading = true;
            del(`/admin/perfis/${id}`)
              .then((json) => {
                this.loading = false;
                this.loadPerfis();
              })
              .catch((err) => {
                this.loading = false;
                this.$swal({
                  title: 'Falha ao remover',
                  icon: 'error',
                });
              });
          }
        })
        .catch(() => {});
    },
    addAcesso(perfil, perfil_id, modulo_id) {
      let request =  post(`/admin/perfis/${perfil_id}/modulos/${modulo_id}`)

      request
        .then((json) => this.openEdit(perfil, false))
        .catch((error) => {});

      return request;
    },
    remAcesso(perfil, perfil_id, modulo_id) {
      let request =  del(`/admin/perfis/${perfil_id}/modulos/${modulo_id}`)

      request
        .then((json) => this.openEdit(perfil, false))
        .catch((error) => {});

      return request;
    },
    addAcessoModuloAcoes(perfil, perfil_id, modulo_id, modulo_acao_id) {
      let request =  post(`/admin/perfis/${perfil_id}/modulos/${modulo_id}/acao/${modulo_acao_id}`)

      request
        .then((json) => this.openEdit(perfil, false))
        .catch((error) => {});

      return request;
    },
    remAcessoModuloAcoes(perfil, perfil_id, modulo_id, modulo_acao_id) {
      let request =  del(`/admin/perfis/${perfil_id}/modulos/${modulo_id}/acao/${modulo_acao_id}`)

      request
        .then((json) => this.openEdit(perfil, false))
        .catch((error) => {});

      return request;
    },
  },
  beforeMount() {
    this.loadPerfis();
    this.loadTiposColaborador();
    this.loadDescricaoModulo();
  },
};
</script>
